import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import * as _ from 'lodash';

import { MenuItem } from 'primeng/api';
import { TieredMenu } from 'primeng/tieredmenu';

import { Logger, LoggingService } from '@sdpp-web/logger';
import { SharePortalRoutingService } from '@sdpp-web/portal';
import { MenuItemType, PortalContext, PortalContextService, MenuItem as SdppMenuItem } from '@sdpp-web/user';
import { assert, JsonHelper } from '@sdpp-web/util';
import { PredicateBuilderConfig, PredicateBuilderService } from '@sdpp-web/predicate-builder';

import { CSS_SCOPE_ID } from '../../models';
import { HeaderIoService } from '../../services';

interface MenuItemBuildConfig extends MenuItem {
  group?: number;
  order?: number;
  path?: string;
  isActive?: boolean;
  items?: MenuItemBuildConfig[];
}

@Component({
  selector: ApplicationMenuComponent.SELECTOR,
  templateUrl: './application-menu.component.html',
  providers: [SharePortalRoutingService]
})
export class ApplicationMenuComponent implements OnInit, OnChanges {
  /** Appcode of the application */
  @Input()
  public appcode: string;

  /** Id is set to enforce CSS rules */
  @HostBinding('attr.id')
  public idd: string = CSS_SCOPE_ID;

  @ViewChild('menu', { static: false })
  public menu: TieredMenu;

  /** Selector */
  public static readonly SELECTOR: string = 's-application-menu';

  public items$: Observable<MenuItemBuildConfig[]>;
  public applicationLabel$: Observable<string>;
  public portalContext: PortalContext;

  public readonly appsToExcludeFromMenu: string[] = ['qisadmin'];

  private readonly _logger: Logger;

  constructor(
    private readonly _sharePortalRoutingService: SharePortalRoutingService,
    private readonly _portalContextService: PortalContextService,
    private readonly _headerIoService: HeaderIoService,
    private readonly _predicateBuilderService: PredicateBuilderService,
    loggingService: LoggingService
  ) {
    this._sharePortalRoutingService.start();
    this._logger = loggingService.getLogger('BurgerMenu');
  }

  public ngOnInit(): void {
    // inputs are undefined when ApplicationMenuComponent is used as web component, use ngOnChanges hook instead
    setTimeout(() => {
      assert(this.appcode, `missing attribute in ${ApplicationMenuComponent.SELECTOR}: appcode`);

      this._portalContextService.getPortalContext().subscribe(portalContext => {
        this.portalContext = portalContext;
        this._loadMenuItems();
      });
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.appcode?.firstChange) {
      this._loadMenuItems();
    }
  }

  private _loadMenuItems(): void {
    this.items$ = this._sharePortalRoutingService.activeRoute$.pipe(
      tap(() => {
        this._sharePortalRoutingService.setRouteLabel(null);
      }),
      // rebuild the menu anytime the active route changes
      map(activeRoute => {
        const items = this._buildMenuChildren(this.portalContext.burgerMenu, this.portalContext, activeRoute);

        const hasActiveMenuItem = items.find(menuItem => menuItem.isActive);

        // if the activeRoute does not match any burger menu entry match the parent route
        if (!hasActiveMenuItem) {
          const activeRoutePathname = activeRoute.split('?')[0];

          return this._activateParentRouteMenu(items, activeRoutePathname);
        }

        return items;
      }),
      tap((items: MenuItemBuildConfig[]) => {
        this._sharePortalRoutingService.dispatchMenu(items);
      })
    );

    this.applicationLabel$ = this.items$.pipe(
      switchMap(items => {
        const result = items.find(menuItem => menuItem.isActive)?.label;

        return result
          ? of(result)
          : this._headerIoService.getHeaderInformation().pipe(
              map(information => {
                const selectedLink = information.link.find(link => link.code === this.appcode);

                return selectedLink && selectedLink.label;
              })
            );
      })
    );
  }

  private _activateParentRouteMenu(items: MenuItemBuildConfig[], activeRoutePathname: string): MenuItemBuildConfig[] {
    const subRoutes = activeRoutePathname.split('/').filter(r => !!r);
    let result: MenuItemBuildConfig[] = items;

    if (subRoutes.length > 1) {
      subRoutes.splice(-1);
      const subRoutePathName = subRoutes.join('/');

      result = items.map(menuItem => {
        const isActive = menuItem.visible ? this._isActive(menuItem, subRoutePathName, null) : false;

        return {
          ...menuItem,
          isActive,
          styleClass: `${isActive ? 'active' : ''} ${this._getAutoId(menuItem)} `
        };
      });

      if (!result.find(i => i.isActive)) {
        return this._activateParentRouteMenu(items, subRoutePathName);
      }
    }

    return result;
  }

  /**
   * Builds the children of the parent item.
   * @param parentItem the Parent item
   * @param context the context for building the menu items
   * @return returns an array of the constructed children (recusrive)
   */
  private _buildMenuChildren(
    parentItem: SdppMenuItem,
    context: PortalContext,
    activeRoute: string
  ): MenuItemBuildConfig[] {
    const [activeRoutePathname, activeRouteSearch] = activeRoute.split('?');

    return parentItem.subMenus
      ? this._orderAndGroup(
          parentItem.subMenus
            .map(menuItem => {
              const subMenu = this._buildMenuChildren(menuItem, context, activeRoute);
              const result: MenuItemBuildConfig = {
                id: menuItem.guid,
                group: menuItem.group,
                order: menuItem.order,
                label: menuItem.label,
                target: menuItem.openInNewTab ? '_blank' : '_self',
                visible: this._evaluateVisibility(menuItem, context, subMenu),
                items: subMenu
              };

              switch (menuItem.type) {
                case MenuItemType.Link: {
                  result.url = menuItem.value;
                  result.path = menuItem.value;
                  break;
                }
                case MenuItemType.Route: {
                  const targetApp = context.applications[menuItem.appCode];
                  if (targetApp) {
                    if (this._isCurrentApp(menuItem)) {
                      result.command = () => {
                        this._sharePortalRoutingService.dispatch(menuItem.value, menuItem.label);

                        // HACK: fixes https://github.com/primefaces/primeng/issues/9534
                        // TODO remove with primeng 11
                        this.menu.hide();
                      };
                      result.url = '';
                      // if menuItem.value is empty (application root) path must be formatted to /appPath to match active route path name
                      result.path = this._formatUrl(menuItem.value ? menuItem.value : targetApp.path);
                    } else {
                      result.command = () => {
                        this._sharePortalRoutingService.setRouteLabel(menuItem.label);
                      };
                      result.url = this._formatUrl(`${targetApp.path}${menuItem.value}`);
                      result.path = result.url;
                    }
                  }
                  break;
                }
                default:
                  break;
              }

              return result;
            })
            // compute isActive, after buildMenuChildren, since it depends on children active status
            .map(menuItem => {
              const isActive = menuItem.visible
                ? this._isActive(menuItem, activeRoutePathname, activeRouteSearch)
                : false;

              return {
                ...menuItem,
                isActive,
                styleClass: `${isActive ? 'active' : ''} ${this._getAutoId(menuItem)} `
              };
            })
        )
      : null;
  }

  // tslint:disable-next-line: cyclomatic-complexity
  private _isActive(menuItem: MenuItemBuildConfig, activeRoutePathname: string, activeRouteSearch: string): boolean {
    if (menuItem.items && menuItem.items.some(item => this._isActive(item, activeRoutePathname, activeRouteSearch))) {
      return true;
    }
    if (menuItem && menuItem.path) {
      // menuItem.url ~~ this._activeRoute
      const [menuItemPathname, menuItemSearch] = menuItem.path.split('?');

      // pathnames must match (remove trailing / from active path name)
      if (menuItemPathname === this._formatUrl(activeRoutePathname)) {
        const menuItemTerms = (menuItemSearch || '').split('&').filter(term => term);
        const activeRouteTerms = (activeRouteSearch || '').split('&').filter(term => term);

        // all query terms from the menu must apprear in the active route
        if (
          menuItemTerms.every(menuItemTerm =>
            activeRouteTerms.some(activeRouteTerm => menuItemTerm === activeRouteTerm)
          )
        ) {
          this._sharePortalRoutingService.setRouteLabel(menuItem.label);

          return true;
        } else {
          // active route matches but parameters don't
          return this._isActive(menuItem, activeRoutePathname, null);
        }
      }
    }
  }

  private _getAutoId(menuItem: MenuItemBuildConfig): string {
    return menuItem && menuItem.label && `autoid-menu-item-${menuItem.label.replace(/ /g, '').toLowerCase()}`;
  }

  private _isCurrentApp(menuItem: SdppMenuItem): boolean {
    return menuItem.type === MenuItemType.Route && menuItem.appCode === this.appcode;
  }

  private _evaluateVisibility(
    menuItem: SdppMenuItem,
    context: PortalContext,
    subMenus: MenuItemBuildConfig[]
  ): boolean {
    try {
      const predicateBuilderConfig = JsonHelper.tryParse<PredicateBuilderConfig>(menuItem.visibilityJson);

      return (!!predicateBuilderConfig ? this._predicateBuilderService.evaluate(predicateBuilderConfig, context) : true)
        ? this._isAVisibleParent(menuItem, subMenus)
        : false;
    } catch (e) {
      this._logger.warn(
        `Hiding menu item '${menuItem.label}', as error was thrown in visibility evaluation, error: ${e}`
      );
    }

    return false;
  }

  private _isAVisibleParent(menuItem: SdppMenuItem, subMenus: MenuItemBuildConfig[]): boolean {
    if (subMenus && subMenus.length > 0) {
      /*
       * MenuItem has children.
       * If menuItem is type Label or Menu, and all of its children are hidden, let's auto-hide
       */
      if (menuItem.type === MenuItemType.Label || menuItem.type === MenuItemType.Menu) {
        return subMenus.some(subMenu => subMenu.visible);
      }
    }

    return true;
  }

  private _orderAndGroup(items: MenuItemBuildConfig[]): MenuItemBuildConfig[] {
    const result: MenuItemBuildConfig[] = [];

    let groupIsVisible: boolean = false;
    let lastItem: MenuItemBuildConfig;

    _.orderBy(items || [], ['group', 'order']).forEach(item => {
      const addSeparator = lastItem && lastItem.group !== item.group && groupIsVisible && item.visible;

      if (addSeparator) {
        result.push({ separator: true }, item);
      } else {
        result.push(item);
      }

      // group is visible if at least one item of the same group is visible.
      groupIsVisible = lastItem
        ? lastItem.group === item.group
          ? groupIsVisible || item.visible
          : false
        : item.visible;

      lastItem = item;
    });

    return result;
  }

  private _formatUrl(urlToFormat: string): string {
    if (urlToFormat != null) {
      urlToFormat = urlToFormat.trim();
      if (!urlToFormat.startsWith('/')) {
        urlToFormat = `/${urlToFormat}`;
      }
      if (urlToFormat.endsWith('/')) {
        urlToFormat = urlToFormat.slice(0, -1);
      }

      return urlToFormat;
    }

    return '';
  }
}
