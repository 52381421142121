import { CSS_SCOPE_ID } from './css-scope-id';

const DIALOG_MASK_SELECTOR: string = '.notification-dialog-mask';

export function bindDialogMaskId(): void {
  const masks: NodeListOf<Element> = document.body.querySelectorAll(DIALOG_MASK_SELECTOR);
  if (masks.length > 0) {
    const mask: Element = masks.item(masks.length - 1);
    mask.setAttribute('id', CSS_SCOPE_ID);
  }
}
