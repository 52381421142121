import { EnumHelper } from '@sdpp-web/util';

export enum NotificationOperationKey {
  NEWS = 'NEWS',
  ACTION = 'ACTION',
  INFO = 'INFO',
  WARNING = 'WARNING'
}

export namespace NotificationOperationKeyHelper {
  export function from(name: string): NotificationOperationKey {
    return EnumHelper.fromValue(NotificationOperationKey, name);
  }
}
