import {
  AfterViewChecked,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { combineLatest, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { Dialog } from 'primeng/dialog';

import { assert } from '@sdpp-web/util';

import { bindDialogMaskId, CSS_SCOPE_ID, FooterInformationOutput, FooterLink } from '../../models';
import { ContextInterpolationService, FooterIoService } from '../../services';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: FooterComponent.SELECTOR,
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnChanges, AfterViewChecked {
  // dialog component
  @ViewChild('dialog', { static: false })
  public dialog: Dialog;

  // appcode of the application
  @Input()
  public appcode: string;

  // id is set to enforce CSS rules
  @HostBinding('attr.id')
  public id: string = CSS_SCOPE_ID;

  public static readonly SELECTOR: string = 's-footer';

  // data loaded from server
  public footerInfo: FooterInformationOutput;
  // data loaded from server
  public links: FooterLink[];

  // is modal open
  public displayModal: boolean;
  // modal title
  public modalTitle: string;
  // modal content, html
  public modalContent: string;

  private _currentSubscription: Subscription;
  private _currentCode: string;
  private _fixOverlayStyle: boolean;

  constructor(
    private readonly _footerIoService: FooterIoService,
    private readonly _contextInterpolationService: ContextInterpolationService
  ) {}

  public ngOnInit(): void {
    // inputs are undefined when FooterComponent is used as web component, use ngOnChanges hook instead
    setTimeout(() => {
      assert(this.appcode, `missing attribute in ${FooterComponent.SELECTOR}: appcode`);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('appcode' in changes) {
      this.loadFooterInfo();
    }
  }

  public ngAfterViewChecked(): void {
    if (this._fixOverlayStyle) {
      this._fixOverlayStyle = false;
      bindDialogMaskId();
    }
  }

  public onClick(link: FooterLink): void {
    if (this._currentSubscription) {
      this._currentSubscription.unsubscribe();
    }
    if (this.displayModal && link.code === this._currentCode) {
      this.displayModal = false;
    } else {
      // fix overlay style if opening
      if (!this.displayModal) {
        this.dialog.onShow.pipe(first()).subscribe(() => {
          this._fixOverlayStyle = true;
        });
      }

      // display modal
      this.modalTitle = link.label;
      this.modalContent = null;
      this._currentCode = link.code;
      this.displayModal = true;

      // load content
      this._currentSubscription = this._footerIoService.getFooterContent(link.code).subscribe((result: string) => {
        this.modalContent = result;
        setTimeout(() => {
          this.dialog.center();
        });
      });
    }
  }

  public onClickClose(): void {
    this.displayModal = false;
  }

  public interpolateWithContext(valueToInterpolate: string): string {
    return this._contextInterpolationService.interpolate(valueToInterpolate);
  }

  private loadFooterInfo(): void {
    this._footerIoService.setAppCode(this.appcode);
    combineLatest([
      this._footerIoService.getFooterInformation(),
      this._contextInterpolationService.init(this.appcode)
    ]).subscribe(([footerInfo, _]) => {
      this.footerInfo = footerInfo;
      this.links = this.footerInfo.link;
    });
  }
}
