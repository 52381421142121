import { APP_INITIALIZER, NgZone, Provider } from '@angular/core';

import { fromEvent, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { LoggingService } from '@sdpp-web/logger';
import { ISignalRMonitoring, SignalRStore } from '@sdpp-web/signalr';
import { TranslateService } from '@sdpp-web/translate';
import { ErrorDisplayService } from '@sdpp-web/ui';
import { SessionState, UserSessionService } from '@sdpp-web/user';

import { environment } from '@environment';

import { ErrorMessage } from '../models/enum/error-message';

export function SIGNALR_MONITORING_INITIALIZER_PROVIDER(): Provider {
  return {
    deps: [SignalRStore, LoggingService, UserSessionService, TranslateService, ErrorDisplayService, NgZone],
    multi: true,
    provide: APP_INITIALIZER,
    useFactory: initSignalRMonitoring
  };
}

/**
 * Initializes SignalR monitoring.
 * @param SignalRStore The signalr store.
 * @param loggingService The logging service.
 * @param userSessionService The user session service.
 * @param translateService The translate service.
 * @param errorDisplayService The error display service.
 * @param ngZone The {@link NgZone} Angular object.
 * @returns A Promise of {@link ISignalRMonitoring}.
 */
export function initSignalRMonitoring(
  signalRStore: SignalRStore,
  loggingService: LoggingService,
  userSessionService: UserSessionService,
  translateService: TranslateService,
  _errorDisplayService: ErrorDisplayService,
  ngZone: NgZone
): () => Promise<ISignalRMonitoring> {
  const logger = loggingService.getLogger('signalR');

  const signalRService = signalRStore.get(environment.signalRConfiguration.default.url);

  const monitoring = signalRService.getMonitoring();

  ngZone.runOutsideAngular(() => {
    monitoring.started$.subscribe(() => {
      logger.debug(`started ==> connectionId: ${monitoring.connectionId}`);
    });

    monitoring.connectionSlow$.subscribe(() => {
      logger.debug('connection slow');
    });

    monitoring.reconnecting$.subscribe(() => {
      logger.debug('reconnecting');
    });

    monitoring.reconnected$.subscribe(() => {
      logger.debug('reconnected');
    });

    monitoring.error$.subscribe(error => {
      logger.debug(`error ==> message: ${error.message}`);
    });
  });

  // on Firefox, unloading window would show signalr disconnection message.
  let willUnload = false;
  fromEvent(window, 'beforeunload').subscribe(() => {
    willUnload = true;
  });

  monitoring.disconnected$
    .pipe(
      tap({
        next: disconnection => {
          const message = disconnection != null ? disconnection.message : '';
          logger.debug(`disconnected ==> message: ${message}`);
        }
      }),
      filter(() => !willUnload),
      switchMap(() => userSessionService.sessionState$()),
      // check session state to ensure signalr disconnexion isn't due to session expiration
      filter(sessionState => sessionState !== SessionState.CLOSED)
    )
    .subscribe(() => {
      const message = translateService.translate(
        ErrorMessage.TRANSLATE_DICTIONARY_NAME,
        ErrorMessage.SignalRDisconnected
      );
      logger.debug(`${message}`);

      // TODO: Uncomment this when signalr SSO issues are solved in dev1 env
      // errorDisplayService.displayError({
      //   message: translateService.translate(ErrorMessage.TRANSLATE_DICTIONARY_NAME, ErrorMessage.SignalRDisconnected),
      //   needsReload: false
      // });
    });

  // on user session timeout:
  // - show error message
  // - then stop signalr
  userSessionService
    .sessionStateChange$()
    .pipe(filter(sessionState => sessionState === SessionState.CLOSED))
    .subscribe(() => {
      logger.debug('session timeout ==> stopping signalr connection');

      // TODO: Uncomment this code here and remove code related to updateSessionSubscription() in header.component.ts
      // eslint-disable-next-line capitalized-comments
      /*if (environment.userSessionCheck === 'true') {
        this._errorDisplayService.displayError({
          message: this._translateService.translate(
            ErrorMessage.TRANSLATE_DICTIONARY_NAME,
            ErrorMessage.SessionTimeout
          ),
          needsReload: true
        });
      }*/

      signalRService.stopConnection();
    });

  return () => of(monitoring).toPromise();
}
