<div class="s-application-menu autoid-s-application-menu">
  <div #tooltipContainer class="burger_menu_cont" *ngIf="items$ | async as items; else menuLoader">
    <button
      *ngIf="items && (items.length > 1 || items[0]?.items?.length > 0)"
      class="hamburger autoid-menu-button"
      [class.is-active]="menu?.visible"
      type="button"
      (click)="menu.toggle($event)"
      pTooltip="Applications"
      i18n-pTooltip
      [appendTo]="tooltipContainer"
    >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
    <p-tieredMenu #menu [popup]="true" [model]="items"></p-tieredMenu>
  </div>
  <div class="app_name_cont autoid-application-name">
    <span *ngIf="applicationLabel$ | async as applicationLabel; else appNameLoader">{{ applicationLabel }}</span>
  </div>
</div>

<ng-template #appNameLoader>
  <div class="app_name_loader">
    <div class="loader-container">
      <div class="circle-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #menuLoader>
  <div class="menu_loader">
    <div class="loader-container">
      <div class="circle-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</ng-template>
