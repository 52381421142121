import { StreamMessage } from '@sdpp-web/signalr';

import { SdppNotificationDto } from '../../models/dto';

import { NotificationOperationKey, NotificationOperationKeyHelper } from './notification-operation-key';

export class NotificationStreamMessage {
  public readonly type: NotificationOperationKey;
  public readonly notification: SdppNotificationDto;

  constructor(streamMessage: StreamMessage) {
    this.type = NotificationOperationKeyHelper.from(streamMessage.type);
    this.notification = JSON.parse(streamMessage.data);
  }
}
