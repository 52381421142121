import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environment';

import { SdppNotificationDto } from '../models/dto';

const BASE_URL: string = `/background${environment.baseSdppApi}/notification`;

@Injectable({ providedIn: 'root' })
export class NotificationIoService {
  constructor(private readonly _http: HttpClient) {}

  public get(): Observable<SdppNotificationDto[]> {
    return this._http.get<SdppNotificationDto[]>(`${BASE_URL}/list`);
  }

  public archive(id: number): Observable<boolean> {
    return this._http.post<boolean>(`${BASE_URL}/archived?id=${id}`, null);
  }

  public read(id: number): Observable<boolean> {
    return this._http.post<boolean>(`${BASE_URL}/read?id=${id}&isRead=true`, null);
  }
}
