export interface NotificationParams {
  /**
   * Time in milliseconds to wait before closing a toast
   */
  toastLife: number;
}

export const NOTIFICATION_PARAMS_VALUE: NotificationParams = {
  toastLife: 5_000
};
