import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, first, map, skip, takeUntil, tap } from 'rxjs/operators';

import { MessageService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { OverlayPanel } from 'primeng/overlaypanel';

import { MetaInfoService, WebComponent } from '@sdpp-web/portal';

import { environment } from '@environment';

import { META_CODE } from '../../../../environments/ienvironment';
import { bindDialogMaskId } from '../../../models';
import { Cardinal, NOTIFICATION_PARAMS_VALUE, NotificationUserSetting, SdppNotification } from '../models';
import {
  NotificationPriority,
  NotificationPriorityHelper,
  NotificationToastPosition,
  NotificationType
} from '../models/enums';
import { NotificationService } from '../services';
import { NotificationStreamService } from '../services/streams';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 's-notification',
  styleUrls: ['./notification.component.scss'],
  templateUrl: './notification.component.html',
  providers: [MessageService]
})
export class NotificationComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  /**
   * DialogContainer should be s-header root, so the dialog style is scoped by #s-host selector,
   * and z-index is not relative to header content, then the dialog is higher than its mask
   */
  @Input()
  public dialogContainer: ElementRef;

  @ViewChild('opNotificationList', { static: true })
  public opNotificationList: OverlayPanel;

  @ViewChild('opNotificationBody', { static: true })
  public opNotificationBody: OverlayPanel;

  @ViewChild('audio', { static: true })
  public audio: ElementRef<HTMLAudioElement>;

  @ViewChild('dialog', { static: true })
  public dialog: Dialog;

  public readonly deployUrlHost: string;
  public notifications$: Observable<SdppNotification[]>;
  public count$: Observable<Cardinal>;
  public body: string;
  public toastPosition$: Observable<NotificationToastPosition>;
  public settings$: Observable<NotificationUserSetting>;
  public dialogHeader: string;
  public dialogIcon: NotificationType;
  public dialogContent: string;
  public showDialog: boolean = false;
  public notificationType: typeof NotificationType = NotificationType;
  public notificationPriority: typeof NotificationPriority = NotificationPriority;

  private _fixOverlayStyle: boolean;
  private readonly _destroy$: Subject<void> = new Subject();

  constructor(
    private readonly _messageService: MessageService,
    private readonly _notificationService: NotificationService,
    private readonly _notificationStreamService: NotificationStreamService,
    private readonly _changeDetector: ChangeDetectorRef,
    metaService: MetaInfoService
  ) {
    this.deployUrlHost = metaService.getMetaForWebComponent(META_CODE, WebComponent.DeployUrl);
    this.notifications$ = this._notificationService.getNotifications$();

    this.count$ = this._notificationService.getNumberOfNotifications$().pipe(
      tap((n: Cardinal) => {
        if (n.total === 0) {
          this.opNotificationList.hide();
        }
      })
    );

    this.settings$ = this._notificationService.getNotificationSettings();

    this.toastPosition$ = this.settings$.pipe(
      filter(state => !!state),
      map(state => state.toastPosition)
    );
    this._notificationStreamService.start();
  }

  public ngAfterViewInit(): void {
    // get list once to pop one modal notification
    combineLatest([this.notifications$, this.settings$])
      .pipe(
        filter(notifications => notifications !== undefined),
        first()
      )
      .subscribe(([notifications, settings]) => {
        if (!settings.enabled) {
          return;
        }

        this._notificationService.setAllToasted();
        const modalNotification = notifications.find(
          notification => notification.priority === NotificationPriority.Modal
        );
        this.showDialog = !!modalNotification;
        if (this.showDialog && modalNotification) {
          this.dialogHeader = modalNotification.object;
          this.dialogIcon = modalNotification.type;
          this.dialogContent = modalNotification.body;

          this.dialog.onShow.pipe(first()).subscribe(() => {
            this._fixOverlayStyle = true;
          });
        }
      });

    // get list to toast new notifications (don't toast first get)
    this.notifications$
      .pipe(
        takeUntil(this._destroy$),
        filter(notifications => notifications !== undefined),
        skip(1),
        map(notifications => notifications.filter(notification => !notification.toasted)),
        filter(notifications => notifications.length > 0)
      )
      .subscribe(notifications => {
        if (!this._notificationService?.settings?.enabled) {
          return;
        }
        if (this._notificationService?.settings?.sound) {
          this._playAudio();
        }

        notifications.slice(0, environment.maxNotificationToastNumber).forEach(notification => {
          notification.setToasted();
          this._messageService.add({
            id: notification.messageId,
            severity: NotificationPriorityHelper.toMessageSeverity(notification.priority),
            summary: notification.object,
            detail: notification.body,
            life: NOTIFICATION_PARAMS_VALUE.toastLife,
            closable: false,
            data: {
              type: notification.type
            }
          });
        });
        this._changeDetector.detectChanges();
      });
  }

  public ngAfterViewChecked(): void {
    if (this._fixOverlayStyle) {
      this._fixOverlayStyle = false;
      bindDialogMaskId();
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public showNotifications(): void {
    this._notificationService.readAll();
    this._messageService.clear();
  }

  public remove(notification: SdppNotification): void {
    this._notificationService.archive(notification.messageId);
  }

  public removeAll(): void {
    this._notificationService.archiveAll();
  }

  public hideBody(notification: SdppNotification): void {
    // do not hide notifications with link
    if (this.opNotificationBody && notification.body.indexOf('href') === -1) {
      this.opNotificationBody.hide();
    } else {
      setTimeout(() => {
        this.opNotificationBody.hide();
      }, 1500);
    }
  }

  private _playAudio(): void {
    this.audio.nativeElement.play();
  }
}
