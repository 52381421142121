import { APP_INITIALIZER, Provider } from '@angular/core';
import { NotificationService, OverlayPanelService } from '../services';

export const NotificationProvider: Provider = {
  deps: [NotificationService, OverlayPanelService],
  multi: true,
  provide: APP_INITIALIZER,
  useFactory: initNotificationsFactory
};

/**
 * Loads the current user notifications.
 * @param NotificationService The notification provider instance.
 */
export function initNotificationsFactory(
  notificationService: NotificationService,
  overlayPanelService: OverlayPanelService
): () => void {
  // tslint:disable-next-line: no-void-expression
  return () => {
    notificationService.loadNotifications();
    notificationService.getNotificationSettings();
    overlayPanelService.installOverlayPanelFix();
  };
}
