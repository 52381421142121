import { assertNever } from '@sdpp-web/util';

export enum NotificationPriority {
  // info
  Low = 'LOW',
  Medium = 'MEDIUM',
  Modal = 'MODAL',

  // warn
  High = 'HIGH',

  // error
  Critical = 'CRITICAL'
}

export namespace NotificationPriorityHelper {
  /**
   * Map notification priority to a toast message severity
   * @param priority notification priority
   * @returns toast message severity
   */
  export function toMessageSeverity(priority: NotificationPriority): 'success' | 'info' | 'warn' | 'error' {
    switch (priority) {
      case NotificationPriority.Low:
      case NotificationPriority.Medium:
      case NotificationPriority.Modal:
        return 'info';
      case NotificationPriority.High:
        return 'warn';
      case NotificationPriority.Critical:
        return 'error';
      default:
        assertNever(priority);
    }
  }
}
