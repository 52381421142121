import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { publishLast, refCount } from 'rxjs/operators';

import { SDPP_API_BASE } from '@sdpp-web/portal';
import { UserContextService } from '@sdpp-web/user';

import { ApiConfiguration, HeaderInformationOutput, UsersSearchResponse } from '../models';

@Injectable({
  providedIn: 'root'
})
export class HeaderIoService {
  private readonly API_VERSION: string = ApiConfiguration.API_VERSION;

  private _appCode: string;

  private readonly _headerInfos: Map<string, Observable<HeaderInformationOutput>> = new Map();

  /**
   * Creates an instance of {@link UserApplicationsService}.
   * @param apiConfig The API configuration, see {@link ApiConfiguration}.
   * @param _http The HTTP client.
   * @param urlService The global UrlService.
   */
  constructor(
    @Inject(SDPP_API_BASE) private readonly _sdppApiBase: string,
    private readonly _http: HttpClient,
    private readonly _userContextService: UserContextService
  ) {}

  public setAppCode(appCode: string): void {
    this._appCode = appCode;
  }

  /**
   * @returns header info: app links
   */
  public getHeaderInformation(appCode?: string): Observable<HeaderInformationOutput> {
    appCode = appCode || this._getValidAppCode();

    if (!this._headerInfos.has(appCode)) {
      const params: HttpParams = new HttpParams()
        .set('apiVersion', this.API_VERSION)
        .set('appCode', this._getValidAppCode());

      const headerInfo = this._http
        .get<HeaderInformationOutput>(`${this._sdppApiBase}/user/GetUserHeaderInformation`, {
          params: params
        })
        .pipe(publishLast(), refCount());

      this._headerInfos.set(appCode, headerInfo);
    }

    return this._headerInfos.get(appCode);
  }

  /**
   * @returns UsersSearchResponse contains list of users matching the
   * @param searchTerm search criteria
   */
  public getUsersBySearchTerm(searchTerm: string): Observable<UsersSearchResponse> {
    return this._http.get<UsersSearchResponse>(`${this._sdppApiBase}/user/list`, {
      params: { searchTerm }
    });
  }

  public saveTheme(themeCode: string): Observable<void> {
    return this._userContextService.setTheme(themeCode);
  }

  private _getValidAppCode(): string {
    if (!this._appCode) {
      throw new Error('[HeaderIoService] appCode missing');
    }

    return this._appCode;
  }
}
