import { NotificationToastPosition } from './enums';

export const NOTIFICATION_USER_SETTING_CODE = 'notificationSettings';
export const NOTIFICATION_USER_SETTING_VERSION = '1';

export interface NotificationUserSetting {
  enabled: boolean;
  sound: boolean;
  toastPosition: NotificationToastPosition;
}

export const NOTIFICATION_USER_SETTING_DEFAULT: NotificationUserSetting = {
  enabled: true,
  sound: true,
  toastPosition: NotificationToastPosition.BottomRight
};
