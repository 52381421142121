<div class="sd-footer autoid-s-footer">
  <div class="sd-footer-content">
    <ul class="link-list">
      <li *ngFor="let link of links" [ngSwitch]="link.type" class="autoid-footer-item" id="item-{{ link.code }}">
        <a
          *ngSwitchCase="'email'"
          href="mailto:{{ interpolateWithContext(link.content) }}"
          title="{{ interpolateWithContext(link.content) }}"
          class="autoid-footer-link"
          id="link-{{ link.code }}"
        >
          <i class="fa fa-envelope"></i>{{ interpolateWithContext(link.label) }}</a
        >
        <a
          *ngSwitchCase="'link'"
          href="{{ link.content }}"
          target="_blank"
          class="autoid-footer-link"
          id="link-{{ link.code }}"
          >{{ interpolateWithContext(link.label) }}</a
        >
        <a
          *ngSwitchCase="'modal'"
          href="javascript:void(0)"
          (click)="onClick(link)"
          class="autoid-footer-link"
          id="link-{{ link.code }}"
          >{{ interpolateWithContext(link.label) }}</a
        >
      </li>
    </ul>
    <ng-content></ng-content>
  </div>
</div>

<p-dialog
  #dialog
  [header]="modalTitle"
  [(visible)]="displayModal"
  [modal]="true"
  [resizable]="false"
  styleClass="disclaimer-pop-in large autoid-s-footer-dialog"
>
  <div class="dialog-content autoid-dialog-content" [innerHTML]="modalContent | safeHtml"></div>
  <p-footer
    ><button
      pButton
      type="button"
      class="p-button-secondary autoid-dialog-close-button"
      label="Close"
      (click)="onClickClose()"
    ></button
  ></p-footer>
</p-dialog>
