import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_ID, DoBootstrap, Injector, NgModule, Type } from '@angular/core';
import { createCustomElement, NgElementConstructor } from '@angular/elements';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ElementZoneStrategyFactory } from 'elements-zone-strategy';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { LoggingModule } from '@sdpp-web/logger';
import { SDPP_API_BASE } from '@sdpp-web/portal';
import { SignalRModule } from '@sdpp-web/signalr';
import { TelemetryModule } from '@sdpp-web/telemetry';
import { TranslateModule } from '@sdpp-web/translate';
import { ErrorDisplayModule, LetModule } from '@sdpp-web/ui';
import {
  PORTAL_APPLICATION_CODE,
  PORTAL_CONTEXT_PROVIDERS,
  USER_CONTEXT_PROVIDERS,
  USER_IMPERSONATION_PROVIDERS,
  USER_SESSION_PROVIDERS,
  USER_SETTING_PROVIDERS
} from '@sdpp-web/user';
import { PredicateBuilderModule } from '@sdpp-web/predicate-builder';

import { locale } from '@locale';

import { environment } from '@environment';

import { ApplicationMenuComponent, FooterComponent, HeaderComponent, NotificationComponent } from './components';
import { NotificationProvider } from './components/notification/providers';
import { setTooltipDefaultParams } from './models';
import { SafeHtmlPipe } from './pipes';
import { SIGNALR_MONITORING_INITIALIZER_PROVIDER } from './providers';

@NgModule({
  declarations: [HeaderComponent, ApplicationMenuComponent, NotificationComponent, FooterComponent, SafeHtmlPipe],
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    DialogModule,
    DropdownModule,
    InputSwitchModule,
    InputTextModule,
    MenuModule,
    TieredMenuModule,
    OverlayPanelModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastModule,
    TooltipModule,
    ErrorDisplayModule,
    LetModule,
    SignalRModule.forRoot([
      {
        ...environment.signalRConfiguration.default,
        hubSettings: {
          ...environment.signalRConfiguration.default.hubSettings,
          logging: false
        }
      }
    ]),
    TranslateModule.forRoot([locale]),
    LoggingModule.forRoot(environment.loggingConfiguration),
    TelemetryModule.forRoot(environment.telemetryLoggerId),
    PredicateBuilderModule.forRoot(),
    AutoCompleteModule
  ],
  providers: [
    SIGNALR_MONITORING_INITIALIZER_PROVIDER(),
    USER_IMPERSONATION_PROVIDERS(environment.userImpersonationConfiguration),
    NotificationProvider,
    { provide: APP_ID, useValue: 's-host' },
    { provide: SDPP_API_BASE, useValue: environment.baseSdppApi },
    USER_CONTEXT_PROVIDERS({ appCode: PORTAL_APPLICATION_CODE }),
    USER_SETTING_PROVIDERS({ appCode: PORTAL_APPLICATION_CODE }),
    USER_SESSION_PROVIDERS(),
    PORTAL_CONTEXT_PROVIDERS()
  ]
})
export class AppModule implements DoBootstrap {
  constructor(private readonly _injector: Injector) {}

  public ngDoBootstrap(): void {
    setTooltipDefaultParams();

    this.registerComponent(HeaderComponent, HeaderComponent.SELECTOR);
    this.registerComponent(FooterComponent, FooterComponent.SELECTOR);
    this.registerComponent(ApplicationMenuComponent, ApplicationMenuComponent.SELECTOR);
  }

  private registerComponent<T>(ComponentClass: Type<T>, selector: string): void {
    const strategyFactory: ElementZoneStrategyFactory = new ElementZoneStrategyFactory(ComponentClass, this._injector);
    const el: NgElementConstructor<T> = createCustomElement(ComponentClass, {
      injector: this._injector,
      strategyFactory: strategyFactory
    });
    customElements.define(selector, el);
  }
}
