import { SdppNotificationDto } from './dto';
import { NotificationPriority, NotificationSource, NotificationType } from './enums';

export class SdppNotification {
  public id: number;
  public messageId: string;
  public sender: string;
  public object: string;
  public body: string;
  public displayStartDate: Date;
  public displayEndDate: Date;
  public shown: boolean;
  public source: NotificationSource;
  public type: NotificationType;
  public priority: NotificationPriority;
  public state: string;
  public toasted: boolean;

  constructor(dto: SdppNotificationDto) {
    this.id = dto.id;
    this.messageId = dto.messageId;
    this.sender = dto.sender;
    this.object = dto.object;
    this.body = dto.body;
    this.displayStartDate = dto.displayStartDate ? new Date(dto.displayStartDate) : null;
    this.displayEndDate = dto.displayEndDate ? new Date(dto.displayEndDate) : null;
    this.shown = dto.shown;
    this.source = dto.source;
    this.type = dto.type;
    this.priority = dto.priority;
    this.state = dto.state;
    this.toasted = false;
  }

  public setToasted(): this {
    this.toasted = true;

    return this;
  }
}
