import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@environment';

import { ApiConfiguration, FooterInformationOutput } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FooterIoService {
  private readonly SDPP_ROOT_API: string = environment.baseSdppApi;
  private readonly API_VERSION: string = ApiConfiguration.API_VERSION;
  private _appCode: string;

  /**
   * Creates an instance of {@link UserApplicationsService}.
   * @param apiConfig The API configuration, see {@link ApiConfiguration}.
   * @param http The HTTP client.
   * @param urlService The global UrlService.
   */
  constructor(private readonly http: HttpClient) {}

  public setAppCode(appCode: string): void {
    this._appCode = appCode;
  }

  /**
   * @returns footer info: links
   */
  public getFooterInformation(): Observable<FooterInformationOutput> {
    const params: HttpParams = new HttpParams()
      .set('apiVersion', this.API_VERSION)
      .set('appCode', this._getValidAppCode());

    return this.http.get<FooterInformationOutput>(`${this.SDPP_ROOT_API}/user/GetUserFooterInformation`, {
      params: params
    });
  }

  /**
   * @param code link code
   * @returns modal content
   */
  public getFooterContent(code: string): Observable<string> {
    const params: HttpParams = new HttpParams()
      .set('apiVersion', this.API_VERSION)
      .set('appCode', this._getValidAppCode())
      .set('code', code);

    return this.http.get<string>(`${this.SDPP_ROOT_API}/user/GetUserfooterInformationcontent`, {
      params: params
    });
  }

  private _getValidAppCode(): string {
    if (!this._appCode) {
      throw new Error('[FooterIoService] appCode missing');
    }

    return this._appCode;
  }
}
