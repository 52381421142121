import { Injectable, OnDestroy } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { Logger, LoggingService } from '@sdpp-web/logger';
import { SignalRService, SignalRStore } from '@sdpp-web/signalr';

import { environment } from '@environment';

import { NotificationService } from '../notification.service';
import { NotificationStreamMessage } from './notification-stream-message';

@Injectable({ providedIn: 'root' })
export class NotificationStreamService implements OnDestroy {
  public static readonly ChannelName: string = 'SDPPWEB:NOTIFICATION'; // TODO: should be moved environment.ts !

  private readonly _logger: Logger;
  private readonly _destroy$: Subject<void> = new Subject();
  private _isStarted: boolean = false;
  private readonly _errors$: Subject<number> = new Subject();
  private readonly _signalRService: SignalRService;

  constructor(
    private readonly _signalRStore: SignalRStore,
    private readonly _notificationService: NotificationService,
    loggingService: LoggingService
  ) {
    this._signalRService = this._signalRStore.get(environment.signalRConfiguration.default.url);
    this._logger = loggingService.getLogger('notificationStream');
  }

  public start(): void {
    if (!this._isStarted) {
      this._isStarted = true;
      this._signalRService
        .getStream(NotificationStreamService.ChannelName)
        .pipe(
          takeUntil(this._destroy$),
          map(message => new NotificationStreamMessage(message))
        )
        .subscribe(message => {
          this.processMessage(message);
        });
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public getErrors$(): Observable<number> {
    return this._errors$.asObservable();
  }

  private processMessage(message: NotificationStreamMessage): void {
    this._logger.debug(`received message:${message.type}, notification: ${message.notification.id}}`);
    this._notificationService.addNotification(message.notification);
  }
}
