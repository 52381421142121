import { Injectable } from '@angular/core';

import { BehaviorSubject, concat, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, filter, finalize, map, tap } from 'rxjs/operators';

import { ApplicationUserSettingList, PORTAL_APPLICATION_CODE, UserSettingService } from '@sdpp-web/user';

import {
  Cardinal,
  NOTIFICATION_USER_SETTING_CODE,
  NOTIFICATION_USER_SETTING_DEFAULT,
  NOTIFICATION_USER_SETTING_VERSION,
  NotificationUserSetting,
  SdppNotification
} from '../models';
import { SdppNotificationDto } from '../models/dto';
import { NotificationToastPosition } from '../models/enums';
import { NotificationIoService } from './notification-io.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  public settings: NotificationUserSetting = NOTIFICATION_USER_SETTING_DEFAULT;

  private _initialized: boolean = false;
  private _loading: boolean = false;
  private readonly _settings$: ReplaySubject<NotificationUserSetting> = new ReplaySubject(null);
  private readonly _notificationMap: Map<string, SdppNotification> = new Map();
  private readonly _notifications$: ReplaySubject<SdppNotification[]> = new ReplaySubject(undefined);
  private readonly _n$: BehaviorSubject<Cardinal> = new BehaviorSubject({ total: 0, new: 0 });
  private _notificationLoaded: boolean;

  constructor(
    private readonly _notificationIoService: NotificationIoService,
    private readonly _userSettingService: UserSettingService
  ) {}

  public loadNotifications(): void {
    this._notificationIoService.get().subscribe((res: SdppNotificationDto[]) => {
      this._notificationLoaded = true;
      res.forEach(dto => {
        if (!this._notificationMap.get(dto.messageId)) {
          this._notificationMap.set(dto.messageId, new SdppNotification(dto));
        }
      });
      this._emitNotifications();
    });
  }

  public getNotifications(): Map<string, SdppNotification> {
    return this._notificationMap;
  }

  public getNotifications$(): Observable<SdppNotification[]> {
    //  hack: avoid default null emission

    return this._notifications$.pipe(filter(_ => this._notificationLoaded));
  }

  public getNumberOfNotifications$(): Observable<Cardinal> {
    return this._n$;
  }

  public setAllToasted(): void {
    this._notificationMap.forEach(notification => {
      notification.setToasted();
    });
  }

  public archive(messageId: string): void {
    const notification = this._notificationMap.get(messageId);

    this._notificationMap.delete(messageId);
    this._emitNotifications();

    this._notificationIoService
      .archive(notification.id)
      .pipe(
        catchError(() => {
          this._notificationMap.set(messageId, notification);
          this._emitNotifications();

          return EMPTY;
        })
      )
      .subscribe();
  }

  public archiveAll(): void {
    const notifications = [...this._notificationMap.values()];

    notifications.forEach(notification => {
      this._notificationMap.delete(notification.messageId);
    });
    this._emitNotifications();

    concat(
      ...notifications.map(notification =>
        this._notificationIoService.archive(notification.id).pipe(
          catchError(() => {
            this._notificationMap.set(notification.messageId, notification);
            this._emitNotifications();

            return EMPTY;
          })
        )
      )
    ).subscribe();
  }

  public read(messageId: string): void {
    const notification = this._notificationMap.get(messageId);

    if (notification && !notification.shown) {
      notification.shown = true;
      this._emitNotifications();

      this._notificationIoService
        .read(notification.id)
        .pipe(
          catchError(() => {
            notification.shown = false;
            this._emitNotifications();

            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  public readAll(): void {
    const notifications = [...this._notificationMap.values()].filter(notification => !notification.shown);

    notifications.forEach(notification => {
      notification.shown = true;
    });
    this._emitNotifications();

    concat(
      ...notifications.map(notification =>
        this._notificationIoService.read(notification.id).pipe(
          catchError(() => {
            notification.shown = false;
            this._emitNotifications();

            return EMPTY;
          })
        )
      )
    ).subscribe();
  }

  public getNotificationSettings(): Observable<NotificationUserSetting> {
    if (!this._initialized && !this._loading) {
      this._loadNotificationSettings();
    }

    return this._settings$;
  }

  public addNotification(dto: SdppNotificationDto): void {
    if (!this._notificationMap.get(dto.messageId)) {
      this._notificationMap.set(dto.messageId, new SdppNotification(dto));
    }
    this._emitNotifications();
  }

  public saveNotificationEnabled(enabled: boolean): void {
    const originalValue = this.settings.enabled;

    this.settings.enabled = enabled;
    this._emitSettings();
    this._saveNotificationSettings()
      .pipe(
        catchError(() => {
          this.settings.enabled = originalValue;
          this._emitSettings();

          return EMPTY;
        })
      )
      .subscribe();
  }

  public saveNotificationSound(sound: boolean): void {
    const originalValue = this.settings.sound;

    this.settings.sound = sound;
    this._emitSettings();
    this._saveNotificationSettings()
      .pipe(
        catchError(() => {
          this.settings.sound = originalValue;
          this._emitSettings();

          return EMPTY;
        })
      )
      .subscribe();
  }

  public saveNotificationToastPosition(toastPosition: NotificationToastPosition): void {
    const originalValue = this.settings.toastPosition;

    this.settings.toastPosition = toastPosition;
    this._emitSettings();
    this._saveNotificationSettings()
      .pipe(
        catchError(() => {
          this.settings.toastPosition = originalValue;
          this._emitSettings();

          return EMPTY;
        })
      )
      .subscribe();
  }

  private _loadNotificationSettings(): void {
    this._loading = true;
    this._userSettingService
      .getUserSettings({
        appCode: PORTAL_APPLICATION_CODE,
        code: NOTIFICATION_USER_SETTING_CODE,
        settingsVersion: NOTIFICATION_USER_SETTING_VERSION
      })
      .pipe(
        map((settingsList: ApplicationUserSettingList) => this._getNotificationSetting(settingsList)),
        tap(state => {
          this.settings = state;
          this._initialized = true;
        }),
        finalize(() => {
          this._emitSettings();
          this._loading = false;
        })
      )
      .subscribe();
  }

  private _getNotificationSetting(settingsList: ApplicationUserSettingList): NotificationUserSetting {
    if (settingsList) {
      const setting = settingsList.global.find(
        set => set.code === NOTIFICATION_USER_SETTING_CODE && set.version === NOTIFICATION_USER_SETTING_VERSION
      );

      if (setting) {
        return JSON.parse(setting.value) as NotificationUserSetting;
      }
    }

    return NOTIFICATION_USER_SETTING_DEFAULT;
  }

  private _saveNotificationSettings(): Observable<NotificationUserSetting> {
    return this._userSettingService
      .upsertUserSetting({
        appCode: PORTAL_APPLICATION_CODE,
        code: NOTIFICATION_USER_SETTING_CODE,
        settingsVersion: NOTIFICATION_USER_SETTING_VERSION,
        value: JSON.stringify(this.settings)
      })
      .pipe(map((settingsList: ApplicationUserSettingList) => this._getNotificationSetting(settingsList)));
  }

  private _emitNotifications(): void {
    this._notifications$.next([...this._notificationMap.values()]);

    this._n$.next({
      total: [...this._notificationMap].length,
      new: [...this._notificationMap.values()].filter(notification => !notification.shown).length
    });
  }

  private _emitSettings(): void {
    this._settings$.next(this.settings);
  }
}
