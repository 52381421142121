import { TranslateType } from '@sdpp-web/translate';

import { ErrorMessage } from '../app/models';

export const errorMessage: [ErrorMessage, string][] = [
  [ErrorMessage.SessionTimeout, 'Session expired. Please refresh your page to be redirected to login page.'],
  [
    ErrorMessage.SignalRDisconnected,
    `SignalR disconnected. New header notifications cannot be received anymore. You may try to refresh your page.`
  ]
];

export const locale: TranslateType = {
  errorMessage
};
