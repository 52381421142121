import { Injectable } from '@angular/core';

import { OverlayPanel } from 'primeng/overlaypanel';

@Injectable({
  providedIn: 'root'
})
export class OverlayPanelService {
  // this service aims to fix ExpressionChangedAfterItHasBeenCheckedError error
  // that happens when switching notifications overlay preview too fast
  // TODO: check if this still works/ needed on primeng update
  public installOverlayPanelFix(): void {
    OverlayPanel.prototype.hide = function (this: OverlayPanel): void {
      this.render = false;
      this.overlayVisible = false;
    };

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const onAnimationEndSource: Function = OverlayPanel.prototype.onAnimationEnd;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OverlayPanel.prototype.onAnimationEnd = function (this: OverlayPanel, event: any): void {
      onAnimationEndSource.call(this, event);
      if (event.toState === 'close') {
        this.render = true;
      }
    };
  }
}
