import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { MetaInfoService, MetaName } from '@sdpp-web/portal';
import { User, UserContextService } from '@sdpp-web/user';
import { assert, format } from '@sdpp-web/util';

type InterpolationContext = Pick<User, 'ssoId' | 'firstName' | 'lastName' | 'email' | 'language'> & { portal: string };

@Injectable({ providedIn: 'root' })
export class ContextInterpolationService {
  private _user: User;

  constructor(
    private readonly _userContextService: UserContextService,
    private readonly _metaInfoService: MetaInfoService
  ) {}

  public init(appCode: string): Observable<void> {
    return this._userContextService.getUserContext(appCode).pipe(
      tap(({ user }) => {
        this._user = user;
      }),
      map(() => void 0)
    );
  }

  public interpolate(valueToInterpolate: string): string {
    return format(valueToInterpolate, this._getContext());
  }

  private _getContext(): InterpolationContext {
    assert(this._user, 'ContextInterpolationService must be initialized with init()');

    const { ssoId, firstName, lastName, email, language } = this._user;
    const portal = this._metaInfoService.getMeta(MetaName.Portal);

    return { ssoId, firstName, lastName, email, language, portal };
  }
}
