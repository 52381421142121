<div
  #notificationsActionContainer
  class="action-container"
  pTooltip="Notifications"
  i18n-pTooltip
  [appendTo]="notificationsActionContainer"
  *ngIf="count$ | async as n"
>
  <div
    *sLet="settings$ | async as settings"
    class="action action-notifications sdi sdi-bell-full"
    [class.badge]="n?.new > 0 && settings?.enabled"
    [class.disabled]="n?.total === 0 || !settings?.enabled"
    (click)="n?.total > 0 && opNotificationList.toggle($event)"
  ></div>
</div>

<p-overlayPanel
  #opNotificationList
  styleClass="s-notification-list-overlay-panel"
  [appendTo]="dialogContainer.nativeElement"
  (onShow)="showNotifications()"
  (onHide)="opNotificationBody.hide()"
>
  <div class="notifications" *ngIf="notifications$ | async as notifications">
    <ul>
      <li *ngFor="let notification of notifications">
        <i
          class="sdi icon-title"
          [ngClass]="{
            'sdi-comment': notification.type === notificationType.News,
            'sdi-alert-check': notification.type === notificationType.Action,
            'sdi-information': notification.type === notificationType.Info,
            'sdi-warning-round-stroke': notification.type === notificationType.Warning,
            info:
              notification.priority === notificationPriority.Low ||
              notification.priority === notificationPriority.Medium ||
              notification.priority === notificationPriority.Modal,
            warn: notification.priority === notificationPriority.High,
            error: notification.priority === notificationPriority.Critical
          }"
        ></i>
        <div class="notifications-item-data">
          <div
            (mouseenter)="body = notification.body; opNotificationBody.show($event)"
            (mouseleave)="hideBody(notification)"
          >
            {{ notification.object }}
          </div>
          <div *ngIf="notification.displayStartDate" class="date">
            {{ notification.displayStartDate | date : 'd MMM, h:mm a' }}
          </div>
        </div>
        <div class="notifications-item-actions">
          <i class="icon-delete sdi sdi-delete" (click)="remove(notification)"></i>
        </div>
      </li>
    </ul>
    <div class="notifications-list-actions" *ngIf="notifications.length >= 2">
      <a (click)="removeAll()" i18n>Remove all</a>
    </div>
  </div>
</p-overlayPanel>

<p-overlayPanel
  #opNotificationBody
  styleClass="s-notification-body-overlay-panel"
  [appendTo]="dialogContainer.nativeElement"
>
  <div [innerHtml]="body | safeHtml"></div>
</p-overlayPanel>

<audio #audio>
  <source src="{{ deployUrlHost }}/assets/alert.mp3" type="audio/mpeg" />
</audio>

<p-toast [position]="toastPosition$ | async">
  <ng-template let-message pTemplate="message">
    <span
      class="p-toast-message-icon sdi"
      [ngClass]="{
        'sdi-comment': message.data.type === notificationType.News,
        'sdi-alert-check': message.data.type === notificationType.Action,
        'sdi-information': message.data.type === notificationType.Info,
        'sdi-warning-round-stroke': message.data.type === notificationType.Warning
      }"
    ></span>
    <div class="p-toast-message-text">
      <ng-container>
        <div class="p-toast-summary">{{ message.summary }}</div>
        <div class="p-toast-detail" [innerHtml]="message.detail | safeHtml"></div>
      </ng-container>
    </div>
  </ng-template>
</p-toast>

<p-dialog
  #dialog
  [header]="dialogHeader"
  [(visible)]="showDialog"
  [modal]="true"
  [resizable]="false"
  styleClass="s-notification-dialog autoid-s-notification-dialog"
  maskStyleClass="notification-dialog-mask"
  [appendTo]="dialogContainer.nativeElement"
>
  <div [innerHtml]="dialogContent | safeHtml"></div>

  <p-footer>
    <p-button
      styleClass="p-button-secondary autoid-dialog-close-button"
      label="Close"
      i18n-label
      (onClick)="showDialog = false"
    ></p-button>
  </p-footer>
</p-dialog>
