import {
  ChildConfiguration,
  IConsoleAppender,
  IHttpAppender,
  LoggingConfiguration,
  LogLevel,
  LogLevelHelper
} from '@sdpp-web/logger';

import { IEnvironment } from './ienvironment';

// app environment variables
const APP_CODE = `%[[APP_CODE]]`;
const APP_LOG_LEVEL = LogLevelHelper.from('%[[APP_LOG_LEVEL]]');
const APP_LOGGER_ID = `%[[APP_LOGGER_ID]]`;
const BNPP_CONNECT_APP_CODE = `%[[BNPP_CONNECT_APP_CODE]]`;
const MAX_NOTIFICATION_TOAST_NUMBER = parseInt(`%[[MAX_NOTIFICATION_TOAST_NUMBER]]`, 10);
const ENABLE_WARNING_HEADER = `%[[ENABLE_WARNING_HEADER]]`.toLowerCase() === 'true';

const SIGNALR_DEFAULT_HUB_NAME = `%[[SIGNALR_DEFAULT_HUB_NAME]]`;
const SIGNALR_DEFAULT_HUB_EVENT_NAME = `%[[SIGNALR_DEFAULT_HUB_EVENT_NAME]]`;
const SIGNALR_DEFAULT_HUB_URL = `%[[SIGNALR_DEFAULT_HUB_URL]]`;

// sdpp web environment variables
const SDPP_WEB_BASE_API = `%[[SDPP_WEB_BASE_API]]`;
const SDPP_WEB_LOG_API = `%[[SDPP_WEB_LOG_API]]`;
const SDPP_WEB_TELEMETRY_API = `%[[SDPP_WEB_TELEMETRY_API]]`;
const SDPP_WEB_TELEMETRY_LOGGER_ID = `%[[SDPP_WEB_TELEMETRY_LOGGER_ID]]`;
const SDPP_WEB_TELEMETRY_LOG_LEVEL = LogLevelHelper.from('%[[SDPP_WEB_TELEMETRY_LOG_LEVEL]]');
const SDPP_WEB_IMPERSONATION_ENABLED = `%[[SDPP_WEB_IMPERSONATION_ENABLED]]`.toLowerCase() === 'true';
const SDPP_WEB_IMPERSONATION_HEADER = `%[[SDPP_WEB_IMPERSONATION_HEADER]]`;

export const environment: IEnvironment = {
  baseSdppApi: SDPP_WEB_BASE_API,
  maxNotificationToastNumber: MAX_NOTIFICATION_TOAST_NUMBER,
  production: true,
  bnppConnectAppCode: BNPP_CONNECT_APP_CODE,
  loggingConfiguration: <LoggingConfiguration>{
    root: {
      appenders: [
        <IConsoleAppender>{
          id: 'RootConsoleAppender',
          level: LogLevel.ALL,
          type: 'CONSOLE'
        },
        <IHttpAppender>{
          applicationCode: APP_LOGGER_ID,
          id: 'RootHttpAppender',
          level: APP_LOG_LEVEL,
          type: 'HTTP',
          url: SDPP_WEB_LOG_API
        }
      ],
      level: LogLevel.ALL
    },
    children: [
      <ChildConfiguration>{
        appenders: [
          <IHttpAppender>{
            applicationCode: APP_CODE,
            id: `TelemetryHttpAppender`,
            level: SDPP_WEB_TELEMETRY_LOG_LEVEL,
            type: 'HTTP',
            url: SDPP_WEB_TELEMETRY_API
          }
        ],
        id: SDPP_WEB_TELEMETRY_LOGGER_ID,
        level: SDPP_WEB_TELEMETRY_LOG_LEVEL
      }
    ]
  },
  signalRConfiguration: {
    default: {
      hubName: SIGNALR_DEFAULT_HUB_NAME,
      hubEventName: SIGNALR_DEFAULT_HUB_EVENT_NAME,
      url: SIGNALR_DEFAULT_HUB_URL,
      hubSettings: {
        useDefaultPath: false
      }
    }
  },
  telemetryLoggerId: SDPP_WEB_TELEMETRY_LOGGER_ID,
  userImpersonationConfiguration: {
    enabled: SDPP_WEB_IMPERSONATION_ENABLED,
    header: SDPP_WEB_IMPERSONATION_HEADER
  },
  enableWarningHeader: ENABLE_WARNING_HEADER
};
